import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Banner from './Banner'
import PricingCards from './PricingCards/PricingCards'
import Experienced from '../home/Experienced/Experienced'
function Pricing() {
  return (
    <React.Fragment>
      <Navbar />
      <Banner />
      <PricingCards />
      <Experienced />
      <Footer />
    </React.Fragment>
  )
}

export default Pricing
