import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Navbar as BootstrapNavbar, Container, Nav } from "react-bootstrap";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../../Context/AuthContext";
import { TbMessageChatbotFilled } from "react-icons/tb";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();
  const { _auth, _setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleScroll = () => {
    setScrolling(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isBlogActive = location.pathname.startsWith("/blog");
  const { isAuthenticated, logout } = useKindeAuth();

  // Scroll to the top of the page after clicking a link
  const handleNavigationClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to top after navigating
  };

  return (
    <React.Fragment>
      <BootstrapNavbar
        className={`${scrolling ? "scrolling" : ""}`}
        expand="lg"
        fixed="top"
      >
        <Container>
          <BootstrapNavbar.Brand href="/">
            <img
              src="/images/logo.svg"
              alt="logo"
              width="225px"
              height="auto"
            />
          </BootstrapNavbar.Brand>
          <div className="navbarMenu">
            <BootstrapNavbar.Toggle
              className="customToggle"
              onClick={() => setNavbar(!navbar)}
            />
            <BootstrapNavbar.Collapse>
              <Nav className="ml-auto">
                <BootstrapNavbar.Toggle
                  className="cross"
                  onClick={() => setNavbar(!navbar)}
                />
                <NavLink
                  exact="true"
                  to="/"
                  className="nav-link"
                  activeclassname="active"
                  onClick={() => handleNavigationClick("/")}
                >
                  <span>Home</span>
                </NavLink>
                <NavLink
                  to="/pricing"
                  className="nav-link"
                  activeclassname="active"
                  onClick={() => handleNavigationClick("/pricing")}
                >
                  <span>Pricing</span>
                </NavLink>
                <NavLink
                  to="/contact"
                  className={`nav-link ${isBlogActive ? "active" : ""}`}
                  onClick={() => handleNavigationClick("/contact")}
                >
                  <span>Contact</span>
                </NavLink>

                {/* Chat page */}
                {_auth && (
                  <NavLink
                    to="/chatbot"
                    className={`chat-link ${isBlogActive ? "active" : ""}`}
                    onClick={() => handleNavigationClick("/chatbot")}
                  >
                    <span>
                      Chatbot
                      <TbMessageChatbotFilled />
                    </span>
                  </NavLink>
                 )}

                <Link
                  to="/login"
                  className={`lap_btn ${
                    window.location.pathname === "/login" ? "active" : ""
                  }`}
                >
                  <button className="booking lap_btn">
                    <span>Login</span>
                  </button>
                </Link>
              </Nav>
            </BootstrapNavbar.Collapse>

            {/* Handle login/logout button for mobile view */}
            <>
              {!_auth ? (
                <button
                  className="booking mbl_btn"
                  onClick={() => handleNavigationClick("/login")}
                >
                  <span>Login</span>
                </button>
              ) : (
                <button
                  className="booking mbl_btn"
                  onClick={() => {
                    localStorage.removeItem("jwt");
                    _setAuth(false);
                    handleNavigationClick("/");
                  }}
                >
                  <span>Logout</span>
                </button>
              )}
            </>
          </div>
        </Container>
      </BootstrapNavbar>
    </React.Fragment>
  );
};

export default Navbar;
