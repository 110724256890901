import React, { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "./PricingCards.scss";
import cardDataMonthly from "./PricingCardsListMonthly.json";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import cardDataYearly from "./PricingCardsListYearly.json";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";

function formatFeaturesWithColors(feature) {
  const parts = feature.split(/(\d+)/).map((part, index) =>
    /\d+/.test(part) ? (
      <span key={index} className="numbers">
        {part}
      </span>
    ) : (
      part
    )
  );
  return <>{parts}</>;
}

function PricingCards() {
  const [loading, setLoading] = useState([]); // New state for loading buttons

  useEffect(() => {
    AOS.init();
  }, []);

  const navigate = useNavigate();
  const { logout, isAuthenticated, user } = useKindeAuth();
  const { _auth } = useContext(AuthContext);

  const handleCheckout = async (

    card_title,
    card_price,
    card_interval,
    card_id
  ) => {
    // Set loading state for the specific card
    setLoading((prev) => ({ ...prev, [card_id]: true }));
    console.log("CZCVCCD",)
    card_price=card_price+"00"
    if (_auth === false) {
      navigate("/login");
    } else {
      try {
        if (card_title === "Free") {
          if (_auth === true) {
            navigate("/chatbot");
          } else if (_auth === false) {
            navigate("/login");
          }
        } else {
          // console.log("card_titleXXXXX", card_title);
          // console.log("card_priceXXXXX", card_price);
          // console.log("card_intervalXXXXX", card_interval);
  
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/create-checkout-session`,
            {
              card_title: card_title,
              card_price: card_price,
              card_interval: card_interval,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          localStorage.setItem("card_title", card_title);
          localStorage.setItem("card_price", card_price);
          localStorage.setItem("card_interval", card_interval);
          localStorage.setItem("payment_id", response.data.sessionId);
  
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.error("Error creating checkout session:", error);
      } finally {
        setLoading((prev) => ({ ...prev, [card_id]: false })); // Reset loading state after completion
      }
    }
  };
  

  return (
    <React.Fragment>
      <div className="pricingCards">
        <div className="container">
          <div className="title" data-aos="fade-up" data-aos-duration="1000">
            <p>
              Get started with a 5-day trial, <span>25% off</span> for Yearly
              Plan, Cancel anytime.
            </p>
          </div>
          <div className="tabsBlock">
            <Tabs defaultActiveKey="monthly" id="uncontrolled-tab-example">
              <Tab eventKey="monthly" title="Monthly">
                <div className="cards zoom-container">
                  <div className="row justify-content-center">
                    {cardDataMonthly.map((card) => (
                      <div
                        className="col-md-4 col-lg-4 my-3"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        key={card.id}
                      >
                        <div className="card">
                          {card.popular ? (
                            <div className="popular">
                              <img src={card.popular} alt="stripeImg" />
                            </div>
                          ) : (
                            ""
                          )}
                          <p className="card-title">{card.title}</p>
                          <p className="price">
                            ${card.price.toString().slice(0, 3)}
                          </p>
                          <p className="card-text">{card.description}</p>
                          <button className="chosebtn"
                            onClick={() =>
                              handleCheckout(
                                card.title,
                                card.price,
                                card.interval,
                                card.id
                              )
                            }
                            disabled={loading[card.id]} // Disable button when loading
                          >
                            {loading[card.id]
                              ? "Processing..."
                              : card.buttonText}{" "}
                            {/* Show loader text */}
                          </button>
                          <ul>
                            {card.features.map((feature, index) => (
                              <li key={index}>
                                {formatFeaturesWithColors(feature)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="yearly" title="Yearly">
                <div className="cards">
                  <div className="row justify-content-center">
                    {cardDataYearly.map((card) => (
                      <div
                        className="col-md-6 col-lg-4 my-3"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        key={card.id}
                      >
                        <div className="card">
                          {card.popular ? (
                            <div className="popular">
                              <img src={card.popular} alt="stripeImg" />
                            </div>
                          ) : (
                            ""
                          )}
                          <p className="card-title">{card.title}</p>
                          <p className="price">
                            ${card.price.toString().slice(0, 3)}
                          </p>
                          <p className="card-text">{card.description}</p>
                          <button
                            onClick={() =>
                              handleCheckout(
                                card.title,
                                card.price,
                                card.interval,
                                card.id
                              )
                            }
                            disabled={loading[card.id]} // Disable button when loading
                          >
                            {loading[card.id]
                              ? "Processing..."
                              : card.buttonText}{" "}
                            {/* Show loader text */}
                          </button>
                          <ul>
                            {card.features.map((feature, index) => (
                              <li key={index}>
                                {formatFeaturesWithColors(feature)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PricingCards;
