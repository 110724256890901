import React from 'react'
import "./ContactUs.scss";

function Banner() {
  return (
    <React.Fragment>
      <div className="contactBanner">
        <div className="container">
            <div className="title">
                <h2>Contact Us</h2>
                <p>Home / Contacts</p>
            </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Banner
