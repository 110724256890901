import React from 'react'
import "./Pricing.scss";

function Banner() {
  return (
    <React.Fragment>
      <div className="pricingBanner">
        <div className="container">
            <div className="title">
                <h2>Pricing & Plan</h2>
                <p>Home / Pricing</p>
                <h3>Ready to Get Started? <br />
                Don't Worry, We'll Keep You Under Budget</h3>
            </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Banner