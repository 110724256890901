// src/MyContext.js
import React, { createContext, useEffect, useState } from 'react';

// Create a context
export const MyContext = createContext();

// Create a provider component
export const MyProvider = ({ children }) => {
  const [userData, setUserData] = useState("Hello, World!");

  useEffect(() => {
    setUserData(localStorage.getItem('userData'))

  }, []);

  return (
    <MyContext.Provider value={{ userData, setUserData }}>
      {children}
    </MyContext.Provider>
  );
};
