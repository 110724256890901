import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [_auth, _setAuth] = useState(false); 
  const [userInfo, setUserInfo] = useState({
    'name':null,
    'tokens':null,
    'picture':null
  }); 




  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    console.log('NAME YO FOR AUTH CONTEXT',sessionStorage.getItem('name'))
    if (jwt) {
      _setAuth(true);
      setUserInfo({
        'tokens':sessionStorage.getItem('tokens'),
        'name':sessionStorage.getItem('name'),
        'picture':sessionStorage.getItem('picture')
      })
      console.log('USER INFO YO MAN',userInfo)
    } else {
      _setAuth(false);
    }
    console.log('JWT FROM YO CONTEXT', jwt);
  }, [_auth]);

  return (
    <AuthContext.Provider value={{ _auth, _setAuth, userInfo, setUserInfo  }}>
      {children}
    </AuthContext.Provider>
  );
};
