import { FaUserCircle } from "react-icons/fa";
import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ChatSidebar.scss";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { MyContext } from "../../MyContext/index";
import { AuthContext } from "../../Context/AuthContext";
import { MdWorkspacePremium } from "react-icons/md";

const ChatSidebar = ({
  onNewChat,
  handleChatSelect,
  fetchTodayHistory,
  chats,
  setChats,
  setMessages,
  tokens,
  setTokens,
  packageTitle,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [user_, setUser] = useState({
    firstname: "",
    lastname: "",
    image_data: "",
    email: "",
  });
  const [newChatMessage, setNewChatMessage] = useState("");
  const [chatData, setChatData] = useState([]); // State to hold fetched chat data
  const { logout, getUser, isAuthenticated } = useKindeAuth();
  const { _auth, userInfo } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserData = async () => {
      console.log("FETCH USE DATA AUTH", _auth);
      if (_auth) {
        try {
          fetchTodayHistory();
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log("User is not authenticated.");
      }
    };

    fetchUserData();
  }, []);

  const handleAvatarClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNewChat = async () => {
    console.log("handleNewChat EXECUTED");
    if (onNewChat) {
      await onNewChat();
    }
    const initialChats = {
      "Today History": [],
      "Recent Chats": [],
    };
    const newUuid = uuidv4();
    localStorage.setItem("user_uuid", newUuid);
    setChats(initialChats);
    setShowModal(false);
    setNewChatMessage("");
  };

  const handleChatItemClick = async (uuid_var) => {
    try {
      setMessages([]);

      console.log("handleChatItemClick uuid_var", uuid_var);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/prompts_and_responses`,
        { uuid_var },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const chatData = response.data.response;

      console.log("Fetched chat data:", chatData);

      if (chatData) {
        if (handleChatSelect) {
          handleChatSelect(chatData); // Call the callback function with the selected chat data
        }
      } else {
        console.error("No data returned from the API.");
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  };

  return (
    <div className="chat-sidebar ">
      <div className="chat-lists">
        {Object.keys(chats).map((key) => (
          <div className="history" key={key}>
            <h5 className="chat-title">{key}</h5>
            <div className="chat-items">
              {Array.isArray(chats[key]) ? (
                chats[key].map((chat, index) => (
                  <div
                    key={index}
                    className="chat-item"
                    onClick={() => handleChatItemClick(chat.uuid_var)}
                  >
                    {chat.chat_title}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="userProfile" onClick={handleAvatarClick}>
          <FaUserCircle className=" iconbg" />
          <div className="user-info">
            <h5>{userInfo.name} </h5>
            <p>
              {packageTitle ? (
                <>
                  <MdWorkspacePremium /> {packageTitle}
                </>
              ) : null}
            </p>

            <p>
              <img src="/images/tokon.svg" alt="" /> {tokens} Tokens
            </p>
          </div>
        </div>
        <button className="new-chat-button" onClick={handleNewChat}>
          + New Chat
        </button>
      </div>
      {chatData.length > 0 && (
        <div className="chat-data">
          <h5>Chat Data:</h5>
          {chatData.map((item, index) => (
            <div key={index}>
              <p>Formatted String: {item.formatted_string}</p>
              <p>Generated Text: {item.generated_text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatSidebar;
