import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import "./Chatbot.scss";
import ChatSidebar from "./ChatSidebar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import Navbar from "../navbar/Navbar";
import { AuthContext } from "../../Context/AuthContext";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [savedChats, setSavedChats] = useState([]);
  const [tokens, setTokens] = useState();
  const [chatUuid, setChatUuid] = useState();
  const [loading, setLoading] = useState(false); 
  const [packageTitle, setPackageTitle] = useState(null); 

  const { _auth, userInfo } = useContext(AuthContext);
  const navigate = useNavigate()

  const initialChats = {
    "Today History": [],
    "Recent Chats": [],
  };

  const [chats, setChats] = useState(initialChats);
  const [userData, setUserData] = useState({
    picture: "",
    given_name: "POWER",
    family_name: " MNSX",
  });

  const { isAuthenticated, getUser, user } = useKindeAuth();

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleSend = async () => {

    if (tokens !== 0) {

      if (input.trim()) {
        const newMessage = { text: input, sender: "user" };
        setMessages([...messages, newMessage]);

        const payload = { uuid_var: chatUuid, prompt: input };

        try {
          setLoading(true); // Set loading to true
          setInput("");
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/query`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );

          if (!response.ok) throw new Error("Failed to fetch response");

          const data = await response.json();
          const botResponse = { text: data.response, sender: "bot" };
          setSavedChats([
            ...savedChats,
            {
              id: savedChats.length + 1,
              name: `Chat ${savedChats.length + 1}`,
              input: newMessage,
              response: botResponse,
            },
          ]);
          setMessages((prevMessages) => [...prevMessages, botResponse]);
          setTokens(data.tokens);
        } catch (error) {
          console.error("Error fetching response:", error);
        } finally {
          setLoading(false); // Set loading to false when done
        }
      }
    } else {
      alert("Become a Premium member");
      navigate("/pricing");
    }
  };

  const handleChatSelect = (chatData) => {
    setMessages([]);
    if (chatData && Array.isArray(chatData)) {
      const messages = chatData
        .map((chat) => [
          {
            text: chat.prompt,
            sender: "user",
          },
          {
            text: chat.generated_text,
            sender: "bot",
          },
        ])
        .flat();
      setMessages(messages);
    } else {
      console.error("chatData is not in the expected format:", chatData);
    }
  };

  useEffect(() => {
    setChatUuid(uuidv4());
    fetchTodayHistory();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (_auth) {
        try {
          const user = await getUser();
          setUserData(user);
          fetchTodayHistory();
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log("User is not authenticated.");
      }
    };

    fetchUserData();
  }, [_auth]);

  const fetchTodayHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/responses/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      const todayHistoryResponses = response.data;
      setChats({
        ...chats,
        "Today History": todayHistoryResponses,
      });
    } catch (error) {
      console.error("Error fetching today history:", error);
    }
  };

  const handleSaveChat = async () => {
    setMessages([]);
    setChatUuid(uuidv4());
    fetchTodayHistory();
  };

  useEffect(() => {
    const token_get_func = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/test_get_token`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      console.log('YO RESPONES',response)
      setTokens(response.data.tokens); // Update tokens
      setPackageTitle(response.data.package_title)
    };
    token_get_func();
  }, []);

  useEffect(() => {
    if (_auth) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/save_subscription`,
          {
            package_title: localStorage.getItem("card_title"),
            package_price: localStorage.getItem("card_price"),
            package_interval: localStorage.getItem("card_interval"),
            paymentId: localStorage.getItem("payment_id"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          localStorage.removeItem("payment_id");
          fetchTodayHistory();
          setChatUuid(uuidv4());
          // Fetch updated tokens
          return axios.get(
            `${process.env.REACT_APP_API_URL}/api/test_get_token`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
        })
        .then((response) => {
          setTokens(response.data.tokens);
          setPackageTitle(response.data.package_title)

        })
        .catch((error) => {
          console.error("Error saving subscription:", error);
        });
    }
  }, [_auth]);

  return (
    <div className="chatBot no-scroll">
      <div style={{ marginBottom: "80px" }}>
        <Navbar />
      </div>
      <div className=" row ">
        <div className="col-md-3 p-0">
          <ChatSidebar 
            savedChats={savedChats}
            onNewChat={handleSaveChat}
            handleChatSelect={handleChatSelect}
            fetchTodayHistory={fetchTodayHistory}
            chats={chats}
            setChats={setChats}
            setMessages={setMessages}
            tokens={tokens}
            setTokens={setTokens}
            packageTitle={packageTitle}
          />
        </div>
        <div className="col-md-9 p-0">
          <Slider {...sliderSettings}>
            <div className="messages-container">
              <div className="messages-list">
                {messages.length === 0 ? (
                  <div className="empty-chat-message">
                    <div style={{display:"flex",height:"60vh",width:"100%",justifyContent:"center",alignItems:"center"}}>
                      <img style={{width:"350px"}} src="./images/message.png" alt="" />
                   </div>
                  </div>
                ) : (
                  messages.map((msg, index) => (
                    <div key={index} className={`message-item ${msg.sender}`}>
                      <div className="message-content">
                        <img
                          src={
                            msg.sender === "user"
                              ? "/images/user1.png"
                              : "/images/chatgpt.svg"
                          }
                          alt="avatar"
                          className="avatar"
                        />
                        <div className="message-bubble">
                          <ReactMarkdown>{msg.text}</ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>

              {loading ? (
                <div style={{ display: "flex" }}>
                  <img
                    style={{ width: "40px", marginRight: "20px" }}
                    src="./images/chatgpt.svg"
                    alt="sarete"
                  />
                  <section className="dots-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </section>
                </div>
              ) : (
                <div className="loader"></div>
              )}
            </div>
          </Slider>
          <div className="input-area">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSend();
              }}
            >
              <input
                className="input-field"
                type="text"
                placeholder="Write your message..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                disabled={loading} // Disable input when loading
              />
              <button
                type="submit"
                className="send-button"
                disabled={loading} // Disable button when loading
              >
                <img src="/images/send.svg" alt="send" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
