import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Banner from './Banner'
import ContactForm from './ContactForm'

function Pricing() {
  return (
    <React.Fragment>
      <Navbar />
      <Banner />
        <ContactForm />
      <Footer />
    </React.Fragment>
  )
}

export default Pricing
