import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Experienced.scss';
import AOS from 'aos';
import { AuthContext } from '../../../Context/AuthContext'; // Import AuthContext

function Experienced() {
  const navigate = useNavigate();
  
  useEffect(() => {
    AOS.init();
  }, []);

  const { _auth } = useContext(AuthContext); // Get the authentication state from context

  // Handle navigation based on _auth state
  const handleGetStarted = () => {
    if (_auth) {
      navigate('/chatbot');
    } else {
      navigate('/login');
    }
  };

  return (
    <React.Fragment>
      <div className="experienced">
        <div className='container'>
          <div className="innerSection">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner_content" data-aos="fade-right"
                  data-aos-duration="1000">
                  <div className="title">
                    <h2>We Are <span>Reliable & Experienced</span> Regal Bot Lawyers</h2>
                    {/* Maintain button styling and add authentication logic */}
                    <button onClick={handleGetStarted} className="get-started-btn">
                      Get Started Free
                    </button>
                    <img src="/images/curlArrow.svg" alt="curlArrow" />
                  </div>
                  <div className="bannerImg">
                    <img src="/images/experienced.svg" alt="experienced" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Experienced;
