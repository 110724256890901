import React, { lazy, Suspense, useEffect, useContext, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import "./App.scss";

import Pricing from "./components/Pricing/Pricing";
import Login from "./components/Login/Login";
import SignUp from "./components/Login/SignUp";
import ChatBot from "./components/ChatBot/Chatbot";
import ContactUs from "./components/contactUs/ContactUs";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import { MyContext } from "./MyContext/index";

// Lazy loaded components
const Home = lazy(() => import("./components/home/Home"));

// Custom Suspense component with delay
const DelayedSuspense = ({ fallback, delay, children }) => {
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowFallback(true), delay);

    return () => clearTimeout(timeout); // Clean up on unmount or refresh
  }, [delay]);

  return (
    <>
      {!showFallback && <div style={{ visibility: "hidden" }}>{fallback}</div>}{" "}
      {/* Reserve space */}
      <Suspense fallback={showFallback ? fallback : null}>{children}</Suspense>
    </>
  );
};

const Config = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const { setUserData } = useContext(MyContext);

  return (
    <KindeProvider
      clientId="69f6245c282c4b73a3984d3b3d00a916"
      domain="https://massdriver.kinde.com"
      redirectUri="http://localhost:3000/chatbot"
      logoutUri="http://localhost:3000"
    >
      <Router>
   
        {/* Use DelayedSuspense here with fallback loader */}
        <DelayedSuspense
          fallback={
            <section className="dots-container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </section>
          }
          // 2-second delay before showing the fallback
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/chatbot" element={<ChatBot />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/forget" element={<ForgetPassword />} />
          </Routes>
        </DelayedSuspense>
      </Router>
    </KindeProvider>
  );
};

export default Config;
