import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCW70fMMzR93ET-LFiliYq22ofcOMRI6Uc",
    authDomain: "regal-bot-8a0cc.firebaseapp.com",
    projectId: "regal-bot-8a0cc",
    storageBucket: "regal-bot-8a0cc.appspot.com",
    messagingSenderId: "138071540718",
    appId: "1:138071540718:web:0881a16d7dab6005c584ff",
    measurementId: "G-5WQWLDQHWY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

// Function to sign up with email and password
const signUpWithEmail = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Send email verification
    await sendEmailVerification(user);

    return user; // Return user object if needed
  } catch (error) {
    // Customize error messages based on error codes
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Email already in use.');
    } else if (error.code === 'auth/weak-password') {
      throw new Error('Password should be at least 6 characters.');
    } else {
      throw error;
    }
  }
};

// Function to sign in with email and password
const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Check if the user's email is verified
    if (!user.emailVerified) {
      throw new Error('Email not verified. Please verify your email before signing in.');
    }

    return user;
  } catch (error) {
    console.error('Error signing in:', error.message);
    throw error;
  }
};

// Function to sign in with Google
const signInWithGoogle = async () => {
  try {
    const result = await auth.signInWithPopup(GoogleProvider);
    const user = result.user;
    return user;
  } catch (error) {
    console.error('Error signing in with Google:', error.message);
    throw error;
  }
};

export { auth, GoogleProvider, signUpWithEmail, signInWithEmail, signInWithGoogle, FacebookProvider };
