import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Login.scss";
import { signInWithPopup } from "firebase/auth";
import axios from "axios";

import { auth, GoogleProvider, signInWithEmail, FacebookProvider } from "../../Firebase/Config";
import { AuthContext } from "../../Context/AuthContext";
import { IoMdCloseCircle } from "react-icons/io";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // State to handle error messages
  const { _setAuth } = useContext(AuthContext);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle email sign-in
 // Handle email sign-in
const handleEmailSignIn = async () => {
  setLoading(true);
  setError(""); // Clear previous errors

  // Validate email format
  if (!validateEmail(email)) {
    setError("Invalid email address.");
    setLoading(false);
    return;
  }

  // Ensure password is not empty
  if (password.trim() === "") {
    setError("Password cannot be empty.");
    setLoading(false);
    return;
  }

  try {
    const userCredential = await signInWithEmail(email, password);
    const user = userCredential;
    console.log('USER EMAIL VEICIED',user.emailVerified )

    // Check if the user's email is verified
    // if (!user.emailVerified) {
    //   throw { code: "auth/email-not-verified" }; // Custom error for email not verified
    // }

    const idToken = await user.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/firebase/auth`,
      { idToken },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    sessionStorage.setItem("tokens", response.data.tokens);
    sessionStorage.setItem("name", response.data.name);
    sessionStorage.setItem("picture", response.data.picture);
    localStorage.setItem("jwt", response.data.token);
    _setAuth(true);
    navigate("/chatbot");
  } catch (error) {
    // Handle common Firebase errors
    switch (error.code) {
      case "auth/user-not-found":
        setError("No user found with this email.");
        break;
      case "auth/wrong-password":
        setError("Incorrect password.");
        break;
      case "auth/invalid-email":
        setError("Invalid email address.");
        break;
      case "auth/invalid-credential":
        setError("Incorrect email or password.");
        break;
      case "auth/invalid-email-verified":
        setError("Email not verified.");
        break;
      case "auth/email-not-verified":
        setError("Email not verified. Please check your inbox and verify your email.");
        break;
      default:
        setError("Incorrect email or password.");

        
    }
    console.error("Error during email sign-in:", error);
  } finally {
    setLoading(false);
  }
};


  // Handle Google sign-in
  const SignInWithGoogle = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, GoogleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/firebase/auth`,
        { idToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );


      _setAuth(true);


      sessionStorage.setItem("tokens", response.data.tokens);
      sessionStorage.setItem("name", response.data.name);
      sessionStorage.setItem("picture", response.data.picture);
      localStorage.setItem("jwt", response.data.token);

      
      navigate("/chatbot");
    } catch (error) {
      console.error("Error during sign-in:", error);
    } finally {
      setLoading(false);
    }
  };


  const SignInWithFacebook = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, FacebookProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/firebase/auth`,
        { idToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );


      _setAuth(true);


      sessionStorage.setItem("tokens", response.data.tokens);
      sessionStorage.setItem("name", response.data.name);
      sessionStorage.setItem("picture", response.data.picture);
      localStorage.setItem("jwt", response.data.token);

      
      navigate("/chatbot");
    } catch (error) {
      console.error("Error during sign-in:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <React.Fragment>
      <div className="login">
        <div className="block">
          <img className="back" src="/images/login.svg" alt="login" />
        </div>
        <div className="loginForm">
          <Link to="/">
            <img className="exit-logo" src="/images/cross.png" alt="" />
          </Link>

          <div className="title">
            <h3>
              <img src="/images/logo.svg" alt="logo" />
            </h3>
          </div>
          <div>
            <div className="buttons">
              <button onClick={SignInWithGoogle}>
                <img src="/images/wg.svg" alt="google" />
                Continue With Google
              </button>
              <button onClick={SignInWithFacebook}>
                <img src="/images/facebook.svg" alt="linkedin" />
                Continue With Facebook
              </button>
            </div>
            <div className="or">
              <p>
                <span>Or sign in with email</span>
              </p>
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <img src="/images/f1.svg" alt="img" />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <img src="/images/f2.svg" alt="img" />
            </div>
            <div className="forget">
              <Link to="/forget">Forgot Password?</Link>
            </div>
            {/* Display error message */}
            {error && (
              <p className="error-message">
                <span style={{ marginRight: "15px", fontSize: "25px" }}>
                  <IoMdCloseCircle />
                </span>
                {error}
              </p>
            )}
            <button
              className={`loginBtn ${loading ? "dull-button" : ""}`}
              onClick={handleEmailSignIn}
              disabled={loading}
            >
              {loading ? "Signing in..." : "Sign in with Regal Bot"}
            </button>
          </div>
          <div className="register">
            <p>
              Don't have an account? <Link to="/signUp">Sign Up for Free</Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
