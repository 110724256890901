import React,{useEffect , useState} from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
import AOS from 'aos';
function Footer() {
    useEffect(() => {
        AOS.init();
    }, [])
  return (
    <React.Fragment>
      <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-5" data-aos="fade-right"
    data-aos-duration="1000">
                    <img className='logo' src="/images/logo.svg" alt="logo" />
                    <p>A Magical Tool to Optimize you content for
                      the first know who you're targeting.
                      Identify your target audience.</p>
                </div>

                <div className="col-lg-3 col-md-3" data-aos="fade-down"
    data-aos-duration="1000">
                    <div className="links">
                        <h4>Regal Bot</h4>
                        <ul>
                            {/* <li><Link to="">About</Link></li> */}
                            <li><Link to="/login">Sign in</Link></li>
                            <li><Link to="/signup">Register</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4" data-aos="fade-left"
    data-aos-duration="1000">
                <div className="links">
                        <h4>News & Update</h4>
                        <div className="formGroup">
                            <input type="email" name="email" id="email" placeholder="Enter Your Email" />
                            <button><img src="/images/email.svg" alt="email" /></button>
                        </div>
                        <div className="social">
                        <Link to=""><img src="/images/social1.svg" alt="socialIcon" /></Link>
                        <Link to=""><img src="/images/social2.svg" alt="socialIcon" /></Link>
                        <Link to=""><img src="/images/social3.svg" alt="socialIcon" /></Link>
                        <Link to=""><img src="/images/social4.svg" alt="socialIcon" /></Link>
                        <Link to=""><img src="/images/social5.svg" alt="socialIcon" /></Link>
                    </div>
                    </div>
                </div>
            </div>
            <div className="copyRights">
            <p>Copyright 2024 <span>RegalBot</span>. All Rights Reserved </p>
            </div>
        </div>
        </div>
    </React.Fragment>
  )
}

export default Footer
