import React, { useState } from "react";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { signUpWithEmail } from "../../Firebase/Config";
import { IoMdCloseCircle } from "react-icons/io";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Simple regex for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignUp = async () => {
    if (!validateEmail(email)) {
      setError("Invalid email address.");
      return;
    }
  
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
  
    setIsLoading(true);
    setError("");
  
    try {
      await signUpWithEmail(email, password);
      setSuccessMessage("Signup successful! A verification link has been sent to your email. Please check your inbox and verify your email before logging in.");
      setIsLoading(false);
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    } catch (error) {
      setError(error.message || "An error occurred.");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="login">
        <div className="block">
          <img className="back" src="/images/login.svg" alt="login" />
        </div>
        <div className="loginForm">
          <div className="title">
            <Link to="/">
              <img className="exit-logo" src="/images/cross.png" alt="" />
            </Link>
            <h3>
              <img src="/images/logo.svg" alt="logo" />
            </h3>
          </div>
          <div className="buttons">
            <button>
              <img src="/images/wg.svg" alt="google" />
              Continue With Google
            </button>
            <button>
              <img src="/images/facebook.svg" alt="linkedin" />
              Continue With Facebook
            </button>
          </div>
          <div className="or">
            <p>
              <span>Or sign up with email</span>
            </p>
          </div>
          <div className="form-group">
            <input
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <img src="/images/f1.svg" alt="img" />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img src="/images/f2.svg" alt="img" />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <img src="/images/f2.svg" alt="img" />
          </div>

          {/* Display error message */}
          {error && (
            <p className="error-message">
              <span style={{ marginRight: "15px", fontSize: "25px" }}>
                <IoMdCloseCircle />
              </span>
              {error}
            </p>
          )}

          {/* Display success message */}
          {successMessage && <p className="success">{successMessage}</p>}

          <button
            className="loginBtn"
            onClick={handleSignUp}
            disabled={isLoading}
          >
            {isLoading ? "Signing Up..." : "Sign up with Regal Bot"}
          </button>
          <div className="register">
            <p>
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignUp;
